<template>
    <div>
        <!-- Banner -->
        <div id="banner">
            <div class="wrapper">
                <div class="slider">
                    <swiper class="rdlides" :options="swiperOption">
                        <swiper-slide>
                            <li>
                                <figure>
                                    <img src="~@/assets/images/slider/1.jpg" width="100%" alt="caregiver assisting elder man in walking" />
                                </figure>
                            </li>
                        </swiper-slide>
                        <swiper-slide>
                            <li>
                                <figure>
                                    <img src="~@/assets/images/slider/2.jpg" width="100%" alt="staff assisting elder man in walking" />
                                </figure>
                            </li>
                        </swiper-slide>
                        <swiper-slide>
                            <li>
                                <figure>
                                    <img src="~@/assets/images/slider/3.jpg" width="100%" alt="elder woman assisted by staff" />
                                </figure>
                            </li>
                        </swiper-slide>
                        <swiper-slide>
                            <li>
                                <figure>
                                    <img src="~@/assets/images/slider/4.jpg" width="100%" alt="elder man on wheelchair and staff looking at distance" />
                                </figure>
                            </li>
                        </swiper-slide>
                    </swiper>
                    <div class="slogan_br" v-if="intro.heroSectionIntro" v-html="intro.heroSectionIntro"></div>
                </div>
                <div class="clearfix"></div>
                <div class="bnr_info">
                    <div class="widget-container classic-textwidget custom-classic-textwidget">
                        <div class="classic-text-widget">
                            <h2 class="mb-0 pb-0">Apply to Provide Home Care to Your Loved One and <span>Get Paid.</span> </h2>
                            <p>We provide skilled and none skilled home care services to help older adults and their caregivers age successfully in the comfort of their home.</p>
                            <strong>Excellent Benefits:</strong>
                            <ul class="bullet mt-0">
                                <li>Health Insurance</li>
                                <li>401K</li>
                                <li>Sign-up Bonus</li>
                                <li>Holiday Pay</li>
                                <li>Time and half</li>
                                <li>Tuition assistance for nurse aide training</li>
                                <li>Daily Pay</li>
                            </ul>
                            <router-link :to="{ name: 'ContactUs' }" tag="a">Contact Us</router-link>
                        </div>
                    </div>
                </div>
                <div class="bnr_info2 animate" data-animate="fadeInLeft" data-duration="1.0s">
                    <div class="widget-container classic-textwidget custom-classic-textwidget">
                        <div class="classic-text-widget">
                            <h2>Free In-Home <span>Assessment</span> </h2>
                            <p>There is no charge for the evaluation of home health needs. Set a schedule for an assessment now!</p>
                            <router-link :to="{ name: 'Services' }" tag="a">Click Here</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Banner -->

        <!-- Main -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div id="post-8" class="post-8 page type-page status-publish hentry">
                        <h1 class="h1_title">
                            Welcome to <span>Successful Aging</span> <small>Home Health</small>
                        </h1>
                        <div class="entry-content" v-if="intro.welcomeSectionIntro" v-html="intro.welcomeSectionIntro"></div>
                    </div>
                    <figure class="main_img1">
                        <img src="~@/assets/images/main_img1.jpg" alt="staff assisting elder man in walking">
                    </figure>
                </main>
                <div class="hldr_main_box">
                    <div class="main_box1">
                        <figure class="animate" data-animate="fadeInUp" data-duration="1.0s">
                            <img src="~@/assets/images/main_img2.jpg" alt="caregiver and elder woman smiling">
                        </figure>
                        <div class="widget-container classic-textwidget custom-classic-textwidget">
                            <div class="classic-text-widget">
                                <router-link :to="{ name: 'RNAndLPNSkilled' }" tag="a">
                                    <h2>RN and LPN Skilled <span>Home Health Care</span>Services</h2>
                                </router-link>
                                <p> Let us help you reduce the chances of hospital re-admission and health complications.</p>
                            </div>
                        </div>
                    </div>
                    <div class="main_box2">
                        <figure class="animate" data-animate="fadeInUp" data-duration="2091ms">
                            <img src="~@/assets/images/main_img3.jpg" alt="elder man doing exercise">
                        </figure>
                        <div class="widget-container classic-textwidget custom-classic-textwidget">
                            <div class="classic-text-widget">
                                <router-link :to="{ name: 'AttendantCareOrCompanionshipServices' }" tag="a">
                                    <h2>Attendant Care or <span>Companionship</span> Services</h2>
                                </router-link>
                                <p>We will be happy to visit you at home and provide you with assistance in daily living activities.</p>
                            </div>
                        </div>
                    </div>
                    <div class="main_box3">
                        <figure class="animate" data-animate="fadeInUp" data-duration="3591ms">
                            <img src="~@/assets/images/main_img4.jpg" alt="female nurses smiling">
                        </figure>
                        <div class="widget-container classic-textwidget custom-classic-textwidget">
                            <div class="classic-text-widget">
                                <router-link :to="{ name: 'NurseAideTrainingServices' }" tag="a">
                                    <h2>Nurse Aide <span>Training</span> Services </h2>
                                </router-link>
                                <p>Helping you gain the knowledge and skills to care for your aging or ailing loved one at home.</p>
                            </div>
                        </div>
                    </div>
                    <div class="main_box4">
                        <figure class="animate" data-animate="fadeInUp" data-duration="4727ms">
                            <img src="~@/assets/images/main_img5.jpg" alt="elderly people smiling">
                        </figure>
                        <div class="widget-container classic-textwidget custom-classic-textwidget">
                            <div class="classic-text-widget">
                                <router-link :to="{ name: 'CareManagementEnvironmentalModifications' }" tag="a">
                                    <h2>Care Management <span>Environmental</span> Modifications</h2>
                                </router-link>
                                <p>Trust our reliable care manager to coordinate your short or long-term care arrangement.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- End Main -->

        <!-- Bottom -->
        <div id="bottom1">
            <div class="wrapper">
                <div class="btm1_con">
                    <figure class="btm1_img1 animate" data-animate="fadeInUp" data-duration="1.0s">
                        <img src="~@/assets/images/btm1_img1.png" alt="elderly people with their caregiver">
                    </figure>
                    <div class="btm1_info">
                        <div class="widget-container classic-textwidget custom-classic-textwidget">
                            <div class="classic-text-widget">
                                <h2>
                                    Apply to Provide Home Care to Your Loved One <span>and Get Paid</span>
                                    <small>Successful Aging Home Health</small>
                                </h2>
                                <p>Did you know that you could get paid by caring for a loved one at home? Apply online!</p>
                                <router-link :to="{ name: 'ApplyToProvideHomeCare' }" tag="a">[ <span>Click Here</span> ]</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="btm1_staff_btn animate" data-animate="fadeInRight" data-duration="1.0s">
                        <router-link :to="{ name: 'MeetOurStaff' }" tag="a">
                            Get to Know Us More! <span>Meet Our Staff</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div id="bottom2">
            <div class="wrapper">
                <div class="btm2_con">
                    <figure class="btm2_img1">
                        <img src="~@/assets/images/btm2_img1.jpg" alt="women smiling">
                    </figure>
                    <div class="btm2_info">
                        <div class="widget-container classic-textwidget custom-classic-textwidget">
                            <div class="classic-text-widget">
                                <h2>
                                    Refer Someone to Us
                                    <!-- and <span>Get Compensated</span> -->
                                    <p>Successful Aging Home Health</p>
                                </h2>
                                <p>We would be happy to receive referrals. Please send referrals to us today!</p>
                                <router-link :to="{ name: 'ReferSomeoneToUs' }" tag="a">
                                    [ <span>Click Here</span> ]
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="hldr_btm2_box">
                        <div class="btm2_box1 animate" data-animate="fadeInUp" data-duration="1.0s">
                            <div class="widget-container classic-textwidget custom-classic-textwidget">
                                <div class="classic-text-widget">
                                    <h2>Insurance Accepted</h2>
                                    <router-link :to="{ name: 'InsuranceAccepted' }" tag="a">Click Here</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="btm2_box2 animate" data-animate="fadeInUp" data-duration="2091ms">
                            <div class="widget-container classic-textwidget custom-classic-textwidget">
                                <div class="classic-text-widget">
                                    <h2>Photo Gallery</h2>
                                    <router-link :to="{ name: 'PhotoGallery' }" tag="a">Click Here</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="btm2_box3 animate" data-animate="fadeInUp" data-duration="3409ms">
                            <div class="widget-container classic-textwidget custom-classic-textwidget">
                                <div class="classic-text-widget">
                                    <h2>Watch Our Video</h2>
                                    <router-link :to="{ name: 'WatchOurVideos' }" tag="a">Click Here</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Bottom -->

        <!-- MODAL -->
        <div v-if="showPopup" class="modal-container">
            <span class="external-modal-close" @click="showPopup = false">x</span>
            <div class="modal-popup">
                <span class="modal-close" @click="showPopup = false">x</span>
                <template v-for="(popup, index) in popups">
                    <div class="modal-content" v-show="activeSlide == index" :key="`popup-${index}`">
                        <div class="popup-header" v-if="popup.subject">
                            <h1>{{ popup.subject }}</h1>
                        </div>
                        <div class="popup-body" v-if="popup.content">
                            <div class="popup-content">
                                <div class="popup-txt" v-html="popup.content"></div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="modal-nav" v-if="popups && popups.length && popups.length > 1">
                    <span class="nav-direction nav-next" :class="{ 'is-disabled': activeSlide == (popups.length - 1) }" @click="activeSlide++">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                    <span class="nav-direction nav-prev" :class="{ 'is-disabled': activeSlide == 0 }" @click="activeSlide--">
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { getSettings } from '@/api/apiCalls'

export default {
    data() {
        return {
            popups: [],
            showPopup: false,
            activeSlide: 0,
            swiperOption: {
                centeredSlides: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                },
            },
            intro: {},
        }
    },
    beforeMount () {
        getSettings().then(({ data }) => {
            this.intro = data.data
            this.popups = this.intro.popups

            this.showPopup = this.popups && this.popups.length ? true : false
        })
    },
    mounted() {
        // eslint-disable-next-line
        $('.animate').scrolla({
            mobile: false,
            once: true
        })
    },
}
</script>

<style>
.external-modal-close {
    width: 30px;
    height: 30px;
    background: darkred;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
    line-height: 30px;
    position: fixed;
    top: 15px;
    right: 15px;
    font-size: 18px;
    z-index: 999999999;
}
.modal-nav {
    position: absolute;
    top: 50%;
    margin: 0 auto;
    text-align: center;
    display: block;
    width: 100%;
}
.nav-direction {
    position: absolute;
    color: #fff;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    background: darkred;
    line-height: 0;
    margin: 0 auto;
    cursor: pointer;
    font-size: 18px;
}
.nav-direction.nav-next {
    right: -40px!important;
}
.nav-direction.nav-prev {
    left: -40px!important;
}
.nav-direction.is-disabled {
    cursor: not-allowed!important;
    pointer-events: none!important;
    background: #8b000061!important;
}

@media only screen and (max-width: 600px) {
    .modal-popup {
        width: 75%!important;
    }
}

.bnr_info {
    padding: 5px 50px 20px 43.4%!important;
}

body {
    position: relative;
}

.modal-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999;
}

.modal-container .modal-popup .modal-close {
    width: 30px;
    height: 30px;
    background: darkred;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
    line-height: 30px;
    position: absolute;
    top: -15px;
    right: -10px;
    font-size: 18px;
}

.modal-container .modal-popup h3 {
    font-size: 1.3em;
    color: #000;
    padding: 10px;
    font-weight: bold;
}

.modal-container .modal-popup p {
    font-size: 1.1em;
    color: #454545;
    padding: 1px;
}

.modal-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background: #fff;
    border-radius: 5px;
    z-index: 99999;
}
.modal-popup .popup-header {
    width: 100%;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    background: #2355f2 url(https://rvs-newsletter-popup.vercel.app/Assets/Pattern.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.modal-popup .popup-header h1 {
    text-align: center;
    color: #fff;
    font-size: 34px;
    line-height: 42px;
    font-weight: 600;
}
.modal-popup .popup-body {
    width: 100%;
    padding: 25px 0px;
    display: flex;
    justify-content: center;
}
.modal-popup .popup-body .popup-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
}
.modal-popup .popup-body .popup-content .popup-txt, .modal-popup .popup-body .popup-profile .profile-txt p {
    width: 90%;
    color: #a3a3a3;
    line-height: 20px;
    text-align: center;
    font-weight: 500;
}
.modal-popup .popup-body .popup-content .popup-form {
    width: 100%;
    display: flex;
    column-gap: 10px;
    margin-top: 15px;
}
.modal-popup .popup-body .popup-content .popup-form input, .modal-popup .popup-body .popup-content .popup-form button {
    padding: 15px;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
}
.modal-popup .popup-body .popup-content .popup-form input {
    width: 70%;
    border: 2px solid #e9eaf1;
    background-color: #f5f7fa;
    color: #a3a3a3;
}
.modal-popup .popup-body .popup-content .popup-form button {
    cursor: pointer;
    background-color: #2355f2;
    border: 0px;
    color: #fff;
}
.modal-popup .popup-body .popup-content .popup-alt-txt {
    width: 100%;
    color: #a3a3a3aa;
    line-height: 20px;
    text-align: start;
    font-weight: 500;
}
.modal-popup .popup-body .popup-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
    margin-top: 20px;
}
.modal-popup .popup-body .popup-profile .profile-content {
    display: flex;
    column-gap: 12px;
    align-items: center;
}
.modal-popup .popup-body .popup-profile .profile-content .profile-img img {
    width: 50px;
}
.modal-popup .popup-body .popup-profile .profile-content .profile-details h4 {
    font-weight: 600;
}
.modal-popup .popup-body .popup-profile .profile-content .profile-details p {
    color: #a3a3a3;
    font-size: 14px;
}

@media only screen and (max-width: 600px) {
    .modal-popup {
        width: 100%;
    }
    .modal-popup .popup-header {
        padding: 10px 0px;
    }
}
</style>